import { graphql, Link, Script } from 'gatsby';
import * as React from 'react';
import Footer from '../components/footer/Footer';
import HeaderCommon from '../components/header/HeaderCommon';
import Layout from '../components/layout';
import MediaMentionItem from '../components/media-mention/MediaMentionItem';
import Seo from '../components/seo';
import DetailSection from '../elements/DetailSection';
import SectionTitle from '../elements/SectionTitle';

const MediaMentionDetail = ({ data: { strapiMediaMention } }: any) => (
  <Layout>
    <Seo
      title={strapiMediaMention.metaTitle ?? 'Media Mentions | banco in the news'}
      description={
        strapiMediaMention.metaDescription ??
        'banco latest news, press releases and features. Write to media@banco.com.sg for any news and press opportunities'
      }
    />
    <main className="main-wrapper">
      <HeaderCommon />
      <DetailSection
        title={strapiMediaMention.Title}
        date={strapiMediaMention.Date}
        readTime={strapiMediaMention.ReadMinutes}
        type="media"
      />
      <div className="section section-media-mention-item">
        <div className="container container-detail">
          <div
            dangerouslySetInnerHTML={{
              __html: strapiMediaMention.Content.data.Content.replace(
                /banco-website-static.s3.ap-southeast-1.amazonaws.com/g,
                'website-static.banco.com.sg',
              ).replace('<oembed url', '<oembed data-iframely-url url'),
            }}
          />

          <div className="row row-8 justify-content-md-center">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <Link className="banco-btn btn-borderd btn-fluid" to="/media-mention">
                Back
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-padding section-related-posts">
        <div className="container">
          <SectionTitle title="Related posts" />
          <div className="row row-8">
            {strapiMediaMention.RelatedPosts.map((data: any) => (
              <div className="col-xl-3 col-lg-4 col-md-6 pb--70" key={data.id}>
                <MediaMentionItem data={data} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </main>
    <Script>
      {`function loadIframelyEmbedJs() {
        if (document.querySelectorAll("oembed[url]").length === 0
            && document.querySelectorAll("iframe[src*='iframe.ly']").length === 0) return;
        var iframely = window.iframely = window.iframely || {};
        if (iframely.load) {
          document.querySelectorAll( 'oembed[url]' ).forEach( element => {
            iframely.load( element, element.attributes.url.value );
          });
        } else {
          var ifs = document.createElement('script'); ifs.type = 'text/javascript'; ifs.async = true;
          ifs.src = ('https:' == document.location.protocol ? 'https:' : 'http:') + '//cdn.iframe.ly/embed.js?api_key=ac73f4e694e673b99af1d6';
          ifs.onload = function() {
            document.querySelectorAll( 'oembed[url]' ).forEach( element => {
              iframely.load( element, element.attributes.url.value );
            });
          }
          var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ifs, s);
        }
      }
      // Run after DOM ready.
      loadIframelyEmbedJs();`}
    </Script>
  </Layout>
);

export const pageQuery = graphql`
  query ($slug: String) {
    strapiMediaMention(Slug: { eq: $slug }, RelatedPosts: { elemMatch: {} }) {
      id
      Date
      ReadMinutes
      Title
      Content {
        data {
          Content
        }
      }
      SEO {
        metaTitle
        metaDescription
      }
      RelatedPosts {
        id
        Slug
        Cover {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 500, height: 325)
            }
          }
        }
        Date
        Title
      }
    }
  }
`;

export default MediaMentionDetail;
